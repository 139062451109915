import styled from 'styled-components';

import { font, colors, z, bz } from '../consts/style';
import { fadeInUp } from '../style/animations';

export const PageWrapper = styled.main`
  height: auto;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-start;
  max-width: 100%;
  padding: 0;
  z-index: 100;
`;

export const ReadNextWrapper = styled(PageWrapper)`
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  background: rgba(0, 0, 0, 0.3);
  footer {
    a:hover {
      color: white;
    }
  }
`;

export const PageInner = styled.div`
  max-width: ${props => props.maxWidth || '1080px'};
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 0 20px;

  @media (max-width: 1023px) {
    max-width: 680px;
  }

  pre {
    background: rgba(0, 0, 0, 0.1);
    padding: 0.2rem 0.4rem;
    font-size: 1.2rem;
  }
  button {
    ${font.button};
    background: ${colors.dark};
    border: none;
    color: white;
    padding: 0.35em 0.7em;
    margin-top: 0.7em;
  }
`;

export const PageTitle = styled.h1`
  ${font.h1};
  color: ${colors.black2};
`;

export const PostFeed = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 680px;

  @media (max-width: 1023px) {
    padding: 40px 0;
    max-width: 100%;
  }
`;

export const PostCard = styled.article`
  display: flex;
  width: 100%;
`;

export const PostCardContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  @media (min-width: 795px) {
    .all-posts ${PostCard}:first-child & {
      flex: 0 1 361px;
      flex-flow: column wrap;
      justify-content: space-between;
      padding: 0 0 0 40px;
    }
  }

  a {
    position: relative;
    display: block;
    color: ${colors.dark};
    text-decoration: none;

    &:hover {
      color: ${colors.dark};
      text-decoration: none;
    }
  }

  h2 {
    ${font.h3};
    color: ${colors.black2};
    margin: 24px 0 16px;
  }

  p {
    color: ${colors.black2};
    margin-bottom: 1.5em;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const PostCardMetaContent = styled.div`
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  margin: 2px 0 0;
  color: ${props => props.color || colors.gray4};
  font-size: 16px;
  line-height: 1.375;
  font-weight: 400;
`;

export const PostFull = styled.article`
  width: 100%;
  max-width: 680px;

  @media (max-width: 1023px) {
    max-width: 100%;
  }
`;

export const PostFullHeader = styled.header`
  width: 100%;
  position: relative;
  margin: 0 0 40px;
  padding: 60px 0;
  border-radius: 3px 3px 0 0;
  background: ${props => props.background};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 800px) {
    padding: 36px 0;
  }
  @media (max-width: 500px) {
    padding: 20px 0;
  }
  h1 {
    color: ${colors.white};
    margin: 0 0 32px;
  }

  h2 {
    font-weight: normal;
    font-size: 18px;
    margin: 0 0 16px;
    color: ${colors.gray3};
  }

  ul {
    li {
      color: ${colors.gray1} !important;

      a {
        color: ${colors.gray2};

        &:hover {
          color: ${colors.gray9} !important;
        }
      }
      span {
        color: ${colors.gray1} !important;
      }
    }
  }

  p {
    margin: 0 0 16px;
    color: ${colors.gray3} !important;
  }

  span {
    color: ${colors.gray3};
  }

  svg path {
    fill: ${colors.gray3};
  }
`;

export const PostFullHeaderOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
`;

export const PostFullImage = styled.figure`
  width: 100%;
  margin: 25px 0 50px;
  background: #e3e9ed;
  border-radius: 3px;
  overflow: hidden;
`;

export const PostFullContent = styled.section`
  position: relative;
  margin: 0 auto;
  padding: 0 0 64px;
  min-height: 230px;

  h1,
  h2,
  h3 {
    margin: 0.5em 0 0.4em;
    color: ${colors.black2};
  }

  h1 {
    ${font.h1};
  }

  h2 {
    ${font.h2};
  }

  h3 {
    ${font.h3};
    margin: 40px 0 32px;
  }

  h4 {
    ${font.h4};
    color: ${colors.black};
    margin: 24px 0 16px;
  }

  h5 {
    ${font.h5};
    color: ${colors.black};
    margin: 24px 0 16px;
  }

  p {
    color: ${colors.black2};
    margin: 0 0 16px;
  }

  a {
    color: ${colors.black2};
    word-break: break-word;
    box-shadow: inset 0 -1px 0 #15171a;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: #3eb0ef;
      text-decoration: none;
      box-shadow: inset 0 -1px 0 #3eb0ef;
    }
  }

  ul {
    margin: 0 0 16px;
    padding: 0;
    list-style: none;

    li {
      margin: 0 0 8px;
      line-height: 1.6;
      color: #141414;
      position: relative;
      padding: 0 0 0 16px;

      ::before {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        width: 8px;
        height: 8px;
        background: #141414;
        border-radius: 50%;
      }
      &:first-child {
        margin-top: 0;
      }
    }
  }

  hr {
    position: relative;
    display: block;
    width: 100%;
    margin: 2em 0;
    padding: 0;
    height: 1px;
    border: 0;
    border-top: 1px solid #e3e9ed;
  }
  .gatsby-image-wrapper {
    border-radius: 3px;
  }

  pre {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
  }

  code {
    background: #f3f8fe;
    padding: 4px 8px;
    border-radius: 4px;
    border-left: none;
    max-width: 100%;
    font-size: 16px;
    line-height: 1.6;
    color: #51668f;
    font-family: 'IBM Plex Mono', monospace;
    white-space: pre-wrap;
  }
`;

export const PostInnerImage = styled.figure`
  margin: 2em 0;

  figcaption {
    margin: 1em auto 0;
    width: 100%;
    display: block;
    text-align: center;
    color: #5d7179;
    font-size: 75%;

    a,
    p {
      color: #5d7179;
    }
  }

  img {
    margin: 0 auto;
    max-width: 100%;
    display: block;
  }
`;

export const PostInnerVideo = styled.figure`
  margin: 2em 0;
  position: relative;

  .video-wrapper {
    padding: 0 0 56.25%;
    position: relative;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  figcaption {
    margin: 1em auto 0;
    width: 100%;
    display: block;
    text-align: center;
    color: #5d7179;
    font-size: 75%;

    a,
    p {
      color: #5d7179;
    }
  }
`;

export const PostCardMeta = styled.footer`
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  ${PostFullHeader} & {
    margin: 0;
    padding: 0;
  }
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  ${z.modalBackground};
`;

export const ModalInner = styled.div`
  background: white;
  position: relative;
  max-width: 100%;
  max-height: 100%;
  height: 25rem;
  width: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeInUp} 0.3s;
  ${bz};
  ${font.h1};
  ${z.modal};
`;

export const FeaturedSnippet = styled.div`
  background: ${colors.gray9};
  position: relative;
  padding: 32px 40px;
  margin: 36px 0;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  h2 {
    margin: 0 0 24px;
  }

  h3 {
    margin: 0 0 16px;
  }
`;

//////////////////////////////////////////////// New Styles

export const BlogPostItem = styled.article`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin: 0 0 64px;
`;

export const PostDate = styled.div`
  width: 190px;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.black3};
  font-family: 'IBM Plex Mono', monospace;

  @media (max-width: 1023px) {
    width: 100%;
    margin: 0 0 16px;
  }
`;

export const PostLink = styled.a`
  text-decoration: none;
  color: ${colors.black2};

  &:hover {
    text-decoration: none;
    color: ${colors.ramotionBlue};
  }
`;

export const BlogPostItemContent = styled.div`
  width: calc(100% - 190px);

  @media (max-width: 1023px) {
    width: 100%;
  }

  h2, .h2 {
    display: block;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin: 0 0 16px;
  }

  section p {
    font-size: 16px;
    line-height: 22px;
    color: ${colors.black3};
  }
`;

export const PostTags = styled.div`
  width: 100%;
  display: flex;
  margin: 0 0 8px;
  font-family: 'IBM Plex Mono', monospace;

  ${PostFullHeader} & {
    margin: 0 0 16px;
  }

  a {
    text-decoration: none;
    font-size: 16px;
    line-height: 22px;
    color: ${colors.black3};
    margin-right: 3px;
    box-shadow: none;

    ${PostFullHeader} & {
      color: ${colors.white};
    }

    &:hover {
      text-decoration: none;
      box-shadow: none;
      color: ${colors.ramotionBlue};
    }

    &:not(:last-child):after {
      content: ',';
    }
  }
`;

export const PostMeta = styled.div`
  width: 100%;

  p {
    color: ${colors.gray1};
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 8px;
  }

  svg {
    margin: 0 8px 3px 0;
  }

  span a {
    color: ${colors.ramotionBlue};
    font-weight: 600;
    text-decoration: none;
    box-shadow: none;

    ${PostFullHeader} & {
      color: ${colors.white};
    }

    &:hover {
      box-shadow: none;
      color: ${colors.ramotionBlueHover};
    }
  }

  span + span {
    margin-left: 8px;
    padding-left: 12px;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      width: 4px;
      height: 4px;
      background: ${colors.gray2};
      border-radius: 50%;
      margin-top: -2px;
    }
  }
`;

export const FeaturedPostWrapper = styled.div`
  margin: 0 0 64px;
  padding: 64px 0 32px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  border-bottom: 1px solid ${colors.gray3};

  @media (max-width: 1023px) {
    padding: 64px 0 0;
  }
`;

export const FeaturedPostFeed = styled.div`
  width: calc(100% - 190px);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  @media (max-width: 1023px) {
    width: 100%;
  }

  ${BlogPostItem} {
    width: calc(50% - 32px);
    margin: 0 0 32px;

    @media (max-width: 1023px) {
      width: 100%;
    }

    h1, .h1 {
      display: block;
      font-size: 48px;
      line-height: 54px;
      font-weight: 700;
      margin: 0 0 16px;
    }

    h2, .h2 {
      font-size: 24px;
      line-height: 1.5;
      font-weight: 700;
    }
  }

  ${BlogPostItemContent} {
    width: 100%;
  }
`;

export const FeaturedPostFeedCol = styled.div`
  width: calc(50% - 32px);

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

export const PostFeedWrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  padding: 0;
`;

export const Aside = styled.aside`
  width: 100%;
  max-width: 320px;
  margin: 0 0 0 40px;

  @media (max-width: 1023px) {
    max-width: 100%;
    margin: 0;
  }
`;

export const AsideWidget = styled.div`
  width: 100%;
  padding: 40px;
  margin: 0 0 64px;
  background: ${colors.gray9};
  position: sticky;
  top: 16px;

  &.home-widget {
    margin-top: 74px;
  }

  @media (max-width: 1023px) {
    max-width: 100%;
    margin: 0 0 64px;
  }
`;

export const AuthorWidget = styled.div`
  width: 100%;
  padding: 0;
  margin: 0 0 64px;

  @media (max-width: 1023px) {
    max-width: 100%;
    margin: 0 0 64px;
  }
`;

export const AuthorHeader = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 100px 0 48px;
  margin: 0;
  width: 100%;
  border-bottom: 1px solid #c4c4c4;

  ${AuthorWidget} & {
    border-bottom: none;
    margin: 0;
    padding: 0;
  }

  @media (max-width: 680px) {
    flex-direction: column;
  }
`;

export const AuthorHeaderLogo = styled.div`
  margin: 0 24px 0 0;

  ${AuthorWidget} & {
    margin: 0 16px 0 0;
  }

  & > div {
    display: block !important;
  }
`;

export const AuthorHeaderTitle = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin: 0 0 32px;

  ${AuthorWidget} & {
    margin: 0 0 16px;

    span {
      ${font.h4};
      font-weight: 600;
      text-decoration: none;
      color: ${colors.black2};
    }
  }
`;

export const AuthorHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  width: 100%;
  max-width: 680px;
  font-size: 16px;

  p {
    color: ${colors.black2};
  }

  ${AuthorWidget} & {
    h1,
    h2,
    h3 {
      color: ${colors.black2};
      font-weight: 600;
      line-height: 1.5;
    }

    h1 {
      ${font.h3};
    }

    h2 {
      ${font.h4};
    }

    h3 {
      ${font.h5};
    }

    p {
      color: ${colors.gray1};
      font-size: 14px;
      line-height: 22px;
    }
  }

  @media (max-width: 500px) {
    margin: 0;
    width: auto;
    align-items: center;
    h2 {
      text-align: center;
    }
  }
`;

export const AuthorHeaderInfo = styled.div`
  p {
    font-size: 14px;
    color: ${colors.gray4};
    margin: 0;

    a {
      margin: 0;
      position: relative;
      text-decoration: none;

      &:not(:first-child) {
        margin-left: 8px;
        padding-left: 12px;
        position: relative;

        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          width: 4px;
          height: 4px;
          background: ${colors.gray2};
          border-radius: 50%;
          margin-top: -2px;
        }
      }
    }
  }

  ${AuthorWidget} & {
    p {
      color: ${colors.black2};

      a {
        color: ${colors.black2};
        margin: 0 16px 0 0;
        padding-left: 0;

        &:before {
          display: none;
        }

        &:hover {
          color: ${colors.gray1};
        }
      }
    }
  }
`;
